import React from "react";
import { useSelector } from "react-redux";
import { formatToCurrency } from "../utils";

export default function OrderItems({
	items,
	handleSubmit,
	deleteOrderItem,
	decreaseQty,
	increaseQty,
	initialPurchaseItems
}) {
	const { loading } = useSelector((state) => state.transaction);
	const getTotal = () => {
		return items.reduce((total, item) => total + item.price * item.quantity, 0);
	};
	return (
		<div className="purchases_wrapper p-5">
			<div className="d-flex flex-column gap-5 justify-content-between h-100">
				<div className="Order_items_wrapper">
					{items &&
						items.length > 0 &&
						items.map((item, index) => (
							<div
								key={index}
								className="Order_item purchase_item
								d-flex justify-content-between align-items-center border-bottom py-3
								"
							>
								<div className="d-flex align-items-center gap-3">
									{item?.image && (
										<img
											src={item.image}
											style={{
												width: "32px",
												height: "32px",
												objectFit: "cover",
											}}
											alt="img"
										/>
									)}
									<div className="d-flex flex-column">
										<span className="purchase_item_title">
											{item.productName}
										</span>
										<span
											className="purchase_item_delete"
											onClick={() => deleteOrderItem(item)}
										>
											remove Item
										</span>
									</div>
								</div>
								<div className="d-flex flex-column align-items-end">
									<span className="checkout_value">
										₦{formatToCurrency(item.price, 2)}
									</span>
									<div className="purchase_item_quantity !bg-glow !border-0 mt-[10px]">
										<span
											className="decrease !bg-glow !rounded-[5px]"
											onClick={() => decreaseQty(item)}
										>
											-
										</span>
										<span className="value !bg-dark-gray2 text-glow !border-0 !px-[15px] ">{item.quantity}</span>
										<span
											className="increase !bg-glow !rounded-[5px]"
											onClick={() => increaseQty(item)}
										>
											+
										</span>
									</div>
								</div>
							</div>
						))}
				</div>
				<div className="">
					<div className="d-flex flex-column border-top py-3">
						<div className="d-flex justify-content-between">
							<p className="checkout_title text-white">Total Amount</p>
							<p className="checkout_value text-white">
								₦{formatToCurrency(getTotal(), 2)}
							</p>
						</div>
					</div>
					{JSON.stringify(initialPurchaseItems) !== JSON.stringify(items) &&
					<div className="d-flex flex-column align-items-center border-top gap-4 p-5">
						<div
							className="proceed_btn !bg-glow !text-dark-gray3 !rounded-full"
							onClick={!loading ? handleSubmit : () => {}}
						>
							{loading ? (
								<div className="spinner-border" role="status"></div>
							) : (
								"Save"
							)}
						</div>
					</div>
					}
					{JSON.stringify(initialPurchaseItems) === JSON.stringify(items) &&
					<div className="d-flex flex-column align-items-center border-top gap-4 p-5">
						<div
							className="proceed_btn !bg-light-gray3 !text-dark-gray3 !rounded-full"
							onClick={!loading ? handleSubmit : () => {}}
						>
							{loading ? (
								<div className="spinner-border" role="status"></div>
							) : (
								"Save"
							)}
						</div>
					</div>
					}
				</div>
			</div>
		</div>
	);
}
